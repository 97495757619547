import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PropertyType } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('propertyTypes');

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectById = (id: string) =>
  createSelector(
    selectAllItems,
    (items: PropertyType[]) => items && items.find((p) => p.id === +id),
  );

export const selectError = createSelector(
  selectState,
  getError,
);

export const selectIsLoading = createSelector(
  selectState,
  getIsLoading,
);
